import React from "react"
import styles from "./index.module.scss"

const SuccessPage = ({ successInfo }) => {
  const data = successInfo

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.subtitle}>Voto exitoso</div>
        <div className={styles.title}>¡Gracias por tu voto!</div>
        <div className={styles.mainTextContainer}>
          <div className={styles.mainText}>
            {/* Los ganadores se van a anunciar el miércoles 15 de diciembre a las 22hs
            via streaming */}
            {data.length > 0 && data[0].normalText} <br />
            <a href={data.length > 0 && data[0].link} target="_blank">
              <span className={styles.strongMainText}>
                {data.length > 0 && data[0].goldText}
              </span>
            </a>{" "}
            {data.length > 0 && data[0].who}
          </div>
        </div>
        {/* <div className={styles.bottomText}>
          Sumá goles para cambiar por premios increíbles
          <a
            href="https://elclubdelhincha.com.ar/auth/register"
            target="_parent"
          >
            <h1 className={styles.strongMainText}>registrate acá</h1>
          </a>
        </div> */}
      </div>
    </div>
  )
}

export default SuccessPage
